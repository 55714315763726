<template>
  <div>
    <div  class="category-container">
      <div class="inner-container">
        <div class="category-title-container">
          <div class="text-h5 mx-auto my-3">
            <span>{{ localizedText[language].perfectWith }}</span>
          </div>
        </div>

        <div class="d-flex flex-nowrap align-center">
          <v-btn @click="swipeLeft" icon><v-icon>mdi-arrow-left</v-icon></v-btn>

          <div id="content" ref="content" class="scrolling-div">
            <div v-for="item in items" :key="item.id" class="category-card">
              <ItemComponent :maxWidth="260" :item="item" :promotions="item.promotions"></ItemComponent>
            </div>
          </div>
          <v-btn @click="swipeRight" icon
            ><v-icon>mdi-arrow-right</v-icon></v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ItemComponent from "@/components/ItemComponent";
export default {
  name: "RelatedItemComponent",
  components: { ItemComponent },
  //********************** */
  props: {
    items: {
      type: Array,
    },
  },

  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      currency: "currency",
    }),

    ...mapGetters("Catalog", {
      catalog: "catalog",
    }),
  },

  //********************** */
  data: () => ({
 
    localizedText: {
      en: {
        perfectWith: "Perfect With:",
      },
      ar: {
        perfectWith: "يا زينه مع:",
      },
    },
  }),

  methods: {
   /*  loadItems: function () {
      let outline = this.relatedItemsOulineIds;
      
      if (outline) {
         
        this.items = this.catalog.items.filter((i) =>
          outline.includes(i.outlineId)
        );

         this.show = this.items.length > 0;
      }
    }, */

    scrollTo: function (element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft;
      // Condition to check if scrolling is required
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth ||
            scrollPixels < 0)
        )
      ) {
        // Get the start timestamp
        const startTime =
          "now" in window.performance
            ? performance.now()
            : new Date().getTime();

        let scroll = function (timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress;
          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            return;
          }
        };
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    },
    swipeLeft: function () {
      const content = this.$refs.content;
      this.scrollTo(content, -300, 300);
    },
    swipeRight: function () {
      const content = this.$refs.content;
      this.scrollTo(content, 300, 300);
    },
  },
  mounted() {
    //this.loadItems();
  },
};
</script>

<style>
.category-container {
  padding: 25px;
}

.category-title-container {
  display: flex;
  justify-content: space-between;
  padding: 6px;
}

.scrolling-div {
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
  margin: 0px;
}
.inner-container {
  margin: auto;
  max-width: 1100px;
}

.scrolling-card {
  display: inline-block;
}

.scrolling-div::-webkit-scrollbar {
  display: none;
}

.category-card {
  display: inline-block;
}
.category-card img {
  /*border-radius: 50%;*/
  margin: auto;
  display: block;
}

.category-title {
  text-align: center;
  font-size: 1.2vh;
  /*word-break: break-all;*/
  white-space: normal;
  /*  background-color: orangered;*/
  /*margin-top:2px;*/
}
</style>