<template>
  <ItemDetailComponent :itemId="id"/>
</template>

<script>
import ItemDetailComponent from "@/components/ItemDetailComponent";


export default {
name: "Item",
components: { ItemDetailComponent },
//********************** */
  props: {
    id: {
      type: Number,
    },
  },
 


}
</script>

<style>

</style>