<template>
  <div>
      <CategoryComponent :categoryOutlineId="outlineId"/>
  </div>
</template>

<script>
import CategoryComponent from "@/components/CategoryComponent";
export default {
name: "Category",
components: { CategoryComponent },
//********************** */
  props: {
    outlineId: {
      type: String,
    },
  },
  

}
</script>

<style>

</style>