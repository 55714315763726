<template>
  <GiveawayItemComponent :itemId="id"/>
</template>

<script>
import GiveawayItemComponent from "@/components/GiveawayItemComponent";
export default {
name : "GiveawayItem",
components: { GiveawayItemComponent },
//********************** */
  props: {
    id: {
      type: Number,
    },
  },
}
</script>

<style>

</style>