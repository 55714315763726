<template>
  <div>
    <v-progress-linear
       :active="isLoading"
      indeterminate
      color="black"
    ></v-progress-linear>
    <v-card class="mx-auto my-1" flat max-width="1100">
      <v-breadcrumbs large :items="breadcrumbItems">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
            <v-avatar size="25">
              <v-img :src="item.imageUrl"></v-img>
            </v-avatar>
            <span :style="`color: ${item.color}`">
              {{ item.text }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-card>

    <div class="hidden-sm-and-down">
      <v-card class="d-flex flex-wrap mx-auto my-1" flat max-width="1100">
        <ItemComponent
          :maxWidth="280"
          v-for="item in categoryItems.items"
          :key="item.id"
          :item="item"
           :promotions="item.promotions"
        ></ItemComponent>
      </v-card>
    </div>
    <div class="hidden-md-and-up">
      <div class="d-flex flex-column align-center">
        <ItemComponent
        :maxWidth="310"
          v-for="item in categoryItems.items"
          :key="item.id"
          :item="item"
          :promotions="item.promotions"
        ></ItemComponent>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ItemComponent from "@/components/ItemComponent";
export default {
  name: "CategoryComponent",
  components: { ItemComponent },
  //********************** */
  props: {
    categoryOutlineId: {
      type: String,
    },
  },

  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      currency: "currency",
      countryCode: "countryCode",
    }),

    ...mapGetters("Catalog", {
      catalog: "catalog",
      categoryItems: "categoryItems",
    }),
  },

  //********************** */
  data: () => ({
    isAddingToCart: false,
    isLoading: false,
    breadcrumbItems: [],
    category: { name: "" },
    localizedText: {
      en: {
        home: "Home",
        priceOnSelection: "Price on selection",
      },
      ar: {
        home: "الرئيسية",
        priceOnSelection: "السعر عند الاختيار",
      },
    },
  }),

  methods: {
    loadItems: async function () {
      this.isLoading = true;
     
     let outlineId = this.categoryOutlineId;

      let countryCode = this.countryCode;
      let currencyCode = this.currencyCode;
      let language = this.language;
      try {
        await this.$store.dispatch("Catalog/loadCategoryItems", {
          outlineId,
          countryCode,
          currencyCode,
          language,
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(error);
      }

      var breadcrumbs = [];

      breadcrumbs.push({
        text: this.localizedText[this.language].home,
        disabled: false,
        to: "/",
        color: "black",
        imageUrl: require("@/assets/square.png"),
      });

      breadcrumbs.push({
        text: this.categoryItems.categoryName,
        disabled: true,
        to: "/Category",
        color: "black",
        imageUrl: this.categoryItems.categoryImageUrl,
      });

      this.breadcrumbItems = breadcrumbs;

     
    },
  },
  mounted() {
    this.loadItems();
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    window.snaptr('track', 'PAGE_VIEW');
  },
};
</script>

<style>
</style>