<template>
  <div>
    <v-progress-linear :active="isLoading" indeterminate color="black"></v-progress-linear>
    <div v-if="currentItem">
      <v-card class="mx-auto" flat max-width="1100">
        <v-breadcrumbs large :items="breadcrumbItems">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
              <v-avatar size="25">
                <v-img :src="item.imageUrl"></v-img>
              </v-avatar>
              <span :style="`color: ${item.color}`">
                {{ item.text }}
              </span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-container>
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-card class="ma-1" max-width="500" flat>
                <v-responsive :aspect-ratio="1">
                  <v-carousel v-model="currentImageIndex" height="auto" :show-arrows="false" hide-delimiters>
                    <v-carousel-item :ripple="false" eager class="mx-4" v-for="(image, i) in currentItem.images" :key="i"
                      :src="image.url">
                    </v-carousel-item>
                  </v-carousel>
                </v-responsive>

                <div class="d-flex justify-center">
                  <v-avatar size="56" @click="setCurrentImageIndex(i)" class="mx-2" tile
                    v-for="(image, i) in currentItem.images" :key="image.id">
                    <v-img :src="image.url"></v-img>
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card class="d-flex mx-auto my-1" max-width="1100" flat>
                <v-card class="mx-2" flat>
                  <v-card-subtitle>{{
                    currentItem.categoryName
                  }}</v-card-subtitle>
                  <v-card-title>{{ currentItem.name }}</v-card-title>
                  <v-card-text>
                    <div class="d-flex justify-space-between">
                      <div>
                        <span class="text-h5 ma-2 actualPriceClass">{{
                          displayActualPrice()
                        }}</span>
                        <span v-if="
                          currentItem.hasPromotion &&
                          currentItem.activePromotion.promotionType ==
                          'Discount'
                        " class="original-price">{{ displayUnitPrice() }}</span>
                        <span v-if="
                          currentItem.hasPromotion &&
                          currentItem.activePromotion.promotionType ==
                          'FreeGiftFromList'
                        " class="indigo--text">
                          [{{ localizedText[language].withGift }}]
                        </span>
                      </div>
                      <div class="ma-2">
                        {{ currentItem.contentSize }}
                      </div>
                    </div>

                    <div v-if="currentItem.canAddPlate">
                      <v-checkbox v-model="withPlate" :label="`${localizedText[language].addPlate
                        } (${displayPlateCosts()})`"></v-checkbox>
                      <v-textarea v-if="withPlate" v-model="plateText" outlined
                        :label="localizedText[language].plateText"></v-textarea>
                    </div>
                    <div v-if="currentItem.canAddCard">
                      <v-checkbox v-model="withCard" :label="localizedText[language].addCard"></v-checkbox>
                      <v-textarea v-if="withCard" v-model="cardText" outlined counter="80"
                        :label="localizedText[language].cardText"></v-textarea>
                    </div>
                    <div class="text-body-1 ma-2">
                      {{ currentItem.description }}
                    </div>

                    <br />
                    <br />
                    <v-list v-if="currentItem.itemType == 'Package' && isPackageQuantitiesSet">
                      <v-list-item v-for="packageItem in currentItem.packageItems" :key="packageItem.id">

                        <v-list-item-content>
                          <v-list-item-title v-text="packageItem.name"></v-list-item-title>

                        </v-list-item-content>

                        <div>
                          <v-btn class="ma-1" :disabled="packageQuantities[packageItem.id] < 2" icon color="black"
                            @click="decrementQuantity(packageItem.id)"><v-icon>mdi-minus</v-icon></v-btn>
                          <v-chip label class="ma-1">{{ packageQuantities[packageItem.id] }}</v-chip>
                          <v-btn class="ma-1" icon color="black"
                            @click="incrementQuantity(packageItem.id)"><v-icon>mdi-plus</v-icon></v-btn>
                        </div>
                      </v-list-item>

                      <v-list-item v-if="currentItem.hasPrintOption">

                        <v-list-item-content>
                          <v-textarea outlined :label="localizedText[language].printText" v-model="printText"></v-textarea>
                        </v-list-item-content>
                      </v-list-item>

                    </v-list>

                    <div class="d-flex mx-auto my-3 align-center" v-if="currentItem.isInStock">
                      <v-btn :disabled="disableAddToCart" :loading="isAddingToCart" @click="addToCart()"
                        class="ma-1 white--text" color="black">{{
                          localizedText[language].addToCart
                        }}</v-btn>
                      <v-btn v-if="currentItem.itemType != 'Package'" class="ma-1" :disabled="quantity < 2" icon
                        color="black" @click="quantity -= 1"><v-icon>mdi-minus</v-icon></v-btn>
                      <v-chip class="ma-1">{{ quantity }}</v-chip>
                      <v-btn v-if="currentItem.itemType != 'Package'" class="ma-1" icon color="black"
                        @click="quantity += 1"><v-icon>mdi-plus</v-icon></v-btn>
                    </div>
                    <div class="d-flex mx-auto my-3 align-center" v-else>
                      <v-btn disabled 
                        class="ma-1 white--text" color="black">{{
                          localizedText[language].outOfStock
                        }}</v-btn>
                     
                    </div>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mx-auto" flat max-width="1100">
        <RelatedItemComponent v-if="relatedItems.length > 0" :items="relatedItems" />
      </v-card>
    </div>
    <v-dialog width="310" v-model="giftDialog">
      <v-card v-if="
        currentItem &&
        currentItem.hasPromotion &&
        currentItem.activePromotion.promotionType == 'FreeGiftFromList'
      ">
        <v-card-title>
          {{ localizedText[language].freeGift }}
        </v-card-title>

        <v-divider></v-divider>

        <v-list flat>
          <v-list-item-group v-model="selectedGift">
            <v-list-item v-for="item in currentItem.activePromotion.freeGiftItems" :key="item.id">
              <template v-slot:default="{ active }">
                <v-list-item-avatar>
                  <v-img :src="item.smallThumbnailUrl"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn outlined depressed color="black" dark @click="cancel" block>
                {{ localizedText[language].cancel }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn :dark="selectedGift != undefined" color="black" :disabled="selectedGift == undefined" @click="done"
                block>
                {{ localizedText[language].done }}
              </v-btn></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RelatedItemComponent from "@/components/RelatedItemComponent";


export default {
  name: "ItemDetailComponent",

  components: { RelatedItemComponent },

  //********************** */
  data: () => ({
    breadcrumbItems: [],
    quantity: 1,
    giftDialog: false,
    withPlate: false,
    withCard: false,
    cardText: "",
    plateText: "",
    printText: "",
    selectedGift: undefined,
    currentImageIndex: 0,
    relatedItems: [],
    sliderSettings: {
      dots: true,
      dotsClass: "slick-dots custom-dot-class",
      edgeFriction: 0.35,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
    isAddingToCart: false,
    localizedText: {
      en: {
        priceOnSelection: "Price on selection",
        addPlate: "Add plate?",
        addCard: "Add card?",
        plateText: "Enter the text you want to write on the plate",
        cardText: "Enter the text you want to write on the card",
        addToCart: "Add to cart",
        home: "Home",
        withGift: " + Free Gift ",
        cancel: "Cancel",
        done: "Done",
        freeGift: "Free Gift",
        maxCardText: "Max number of characters for gift card is 80",
        printText: "Print On Box?",
        outOfStock: "Out of stock"
      },
      ar: {
        priceOnSelection: "السعر عند الاختيار",
        addPlate: "اضافة بليت؟",
        addCard: "اضافة كرت؟",
        cardText: "أدخل الكتابة المطلوبة على الكرت",
        addToCart: "أضف إلى السلة",
        home: "الرئيسية",
        withGift: " + هدية مجانية ",
        cancel: "الغاء",
        done: "تم",
        freeGift: "هدية مجانية",
        maxCardText: "عدد الحروف للكرت يجب أن لا يتجاوز 80 حرفا",
        printText: "الكتابة على العلبة؟",
        outOfStock: "نفذت الكمية"
      },
    },
    isLoading: false,
    packageQuantities: {},
    isPackageQuantitiesSet: false,

  }),

  //********************** */
  props: {
    itemId: {
      type: Number,
    },
  },

  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      currency: "currency",
      countryCode: "countryCode",
    }),

    ...mapGetters("Catalog", {
      catalog: "catalog",
      currentItem: "currentItem",
    }),

    actualPriceClass: function () {
      if (
        this.currentItem.promotions &&
        this.currentItem.promotions.length > 0 &&
        this.currentItem.promotions[0].promotionType == "Discount"
      ) {
        return "red--text";
      } else {
        return "";
      }
    },

    disableAddToCart: function () {
      if (this.currentItem.itemType == 'Package') {
        return this.quantity < this.currentItem.numberOfPackageItemsToSelect;
      }
      return false;
    }
  },
  //********************** */
  methods: {
    setCurrentImageIndex: function (index) {
      this.currentImageIndex = index;
    },
    loadItem: async function () {
      let itemId = this.itemId;
      let countryCode = this.countryCode;
      let currencyCode = this.currencyCode;
      let language = this.language;

      try {
        this.isLoading = true;
        await this.$store.dispatch("Catalog/loadCurrentItem", {
          itemId,
          countryCode,
          currencyCode,
          language,
        });

        if (this.currentItem.itemType == 'Package') {
          for (let index = 0; index < this.currentItem.packageItems.length; index++) {
            const element = this.currentItem.packageItems[index];
            this.packageQuantities[element.id] = 0;
          }


          this.isPackageQuantitiesSet = true;
        }


        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(error);
      }

      this.relatedItems = this.currentItem.relatedItems;

      this.breadcrumbItems.push({
        text: this.localizedText[this.language].home,
        disabled: false,
        to: "/",
        color: "black",
        imageUrl: require("@/assets/square.png"),
      });

      this.breadcrumbItems.push({
        text: this.currentItem.categoryName,
        disabled: false,
        to: `/Category/${this.currentItem.categoryOutlineId}`,
        color: "black",
        imageUrl: this.currentItem.categoryImageUrl,
      });

      this.breadcrumbItems.push({
        text: this.currentItem.name,
        disabled: true,
        to: `/item`,
        color: "black",
        imageUrl: "",
      });
    },
    incrementQuantity: function (id) {
      this.quantity += 1;
      this.packageQuantities[id] += 1;
      const sumValues = this.sum(this.packageQuantities);
      this.quantity = sumValues;
    },
    decrementQuantity: function (id) {
      this.quantity += 1;
      this.packageQuantities[id] -= 1;
      const sumValues = this.sum(this.packageQuantities);
      this.quantity = sumValues;
    },
    addToCart: async function () {

      if (this.withCard) {
        if (this.cardText) {
          if (this.cardText.length > 80) {
            alert(this.localizedText[this.language].maxCardText);
            return;
          }
        }
      }
      if (
        this.currentItem.hasPromotion &&
        this.currentItem.activePromotion.promotionType == "FreeGiftFromList"
      ) {
        this.giftDialog = true;
        return;
      }

      this.isAddingToCart = true;

      if (this.currentItem.itemType == "MultiPrice") {
        let specialModel = {
          quantity: this.quantity,
          itemId: this.currentItem.id,
          language: this.language,
        };

        try {
          await this.$store.dispatch(
            "Cart/addItemWithSpecialPromotion",
            specialModel
          );
          window.snaptr('track', 'ADD_CART');
        } catch (error) {
          console.log(error);
        }
        this.isAddingToCart = false;
        return;
      }

      if (this.currentItem.itemType == 'Package') {

        var packageItems = [];
        let self = this;
        Object.keys(self.packageQuantities).forEach(function (key, index) {
          let value = self.packageQuantities[key];
          packageItems.push({ itemId: Number(key), numberOfItems: value })
        });

        let packageModel = {
          quantity: this.quantity,
          itemId: this.currentItem.id,
          language: this.language,
          giftItemId: 0,
          withPlate: this.withPlate,
          withCard: this.withCard,
          plateText: this.plateText,
          cardText: this.cardText,
          isGift: false,
          packageItems: packageItems,
          printText : this.printText
        }
        try {
          await this.$store.dispatch(
            "Cart/addItemWithPackage",
            packageModel
          );
          window.snaptr('track', 'ADD_CART');
        } catch (error) {
          console.log(error);
        }

        this.isAddingToCart = false;


        return;
      }

      let model = {
        quantity: this.quantity,
        itemId: this.currentItem.id,
        language: this.language,
        withPlate: this.withPlate,
        withCard: this.withCard,
        plateText: this.plateText,
        cardText: this.cardText,
      };

      try {
        await this.$store.dispatch("Cart/addItem", model);
        window.snaptr('track', 'ADD_CART');
      } catch (error) {
        console.log(error);
      }

      this.isAddingToCart = false;
    },

    done: async function () {

      this.giftDialog = false;
      this.isAddingToCart = true;

      let giftItem = this.currentItem.activePromotion.freeGiftItems[this.selectedGift];
      let model = {
        quantity: this.quantity,
        itemId: this.currentItem.id,
        language: this.language,
        withPlate: this.withPlate,
        withCard: this.withCard,
        plateText: this.plateText,
        cardText: this.cardText,
        giftItemId: giftItem.id
      };

      try {
        await this.$store.dispatch("Cart/addItemWithGift", model);
        window.snaptr('track', 'ADD_CART');
      } catch (error) {
        console.log(error);
      }

      this.isAddingToCart = false;
    },

    cancel: function () {
      this.giftDialog = false;
      this.selectedGift = undefined;
    },

    sum: function (obj) {
      var sum = 0;
      for (var el in obj) {
        if (obj.hasOwnProperty(el)) {
          sum += parseFloat(obj[el]);
        }
      }
      return sum;
    },
    displayPlateCosts: function () {
      return `${this.currentItem.calculatedPlateCost} ${this.currency.symbol}`;
    },

    displayActualPrice: function () {
      if (this.currentItem.itemType == "GiveawayService") {
        return this.localizedText[this.language].priceOnSelection;
      }

      return `${this.currentItem.calculatedActualPrice} ${this.currency.symbol}`;
    },

    displayUnitPrice: function () {
      return `${this.currentItem.calculatedUnitPrice} ${this.currency.symbol}`;
    },
  },

  //********************** */

  async mounted() {
    await this.loadItem();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    //window.snaptr('track', 'PAGE_VIEW');
    //console.log(this.currentItem)
  },

  created() { },
};
</script>

<style>
.actualPriceClass {
  color: black;
}

.original-price {
  text-decoration: line-through;
}
</style>