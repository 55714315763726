<template>
  <div>
    <v-card flat class="mx-auto" max-width="1100">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-card class="ma-1" max-width="500" flat>
              <v-responsive :aspect-ratio="1">
                <v-carousel
                  v-model="currentImageIndex"
                  height="auto"
                  :show-arrows="false"
                  hide-delimiters
                >
                  <v-carousel-item
                    :ripple="false"
                    @click="carouselItemClicked(image, $event)"
                    eager
                    class="mx-4"
                    v-for="(image, i) in item.images"
                    :key="i"
                    :src="image.url"
                  >
                  </v-carousel-item>
                </v-carousel>
              </v-responsive>

              <!--               <div class="d-flex justify-center">
                <v-icon
                  @click="setCurrentImageIndex(i)"
                  class="mx-2"
                  :color="i == currentImageIndex ? 'black' : 'grey'"
                  small
                  v-for="(image, i) in item.images"
                  :key="image.id"
                >
                  mdi-circle
                </v-icon>
              </div> -->
              <div class="d-flex justify-center">
                <v-avatar
                  size="56"
                  @click="setCurrentImageIndex(i)"
                  class="mx-2"
                  tile
                  v-for="(image, i) in item.images"
                  :key="image.id"
                >
                  <v-img :src="image.url"></v-img>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card class="ma-1" max-width="500" flat>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ currentItem.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    <h2>
                      {{ currentItem.unitPrice * currency.value }}
                      {{ currency.symbol }}
                    </h2>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>

              <v-card-text>
                <div>
                  {{ currentItem.description }}
                </div>
              </v-card-text>
              <v-card class="ma-1" elevation="0">
                <v-card-title>
                  {{ localizedText[language].chooseSize }}
                </v-card-title>
                <v-card-text>
                  <v-chip-group
                    @change="sizeChanged"
                    v-model="selectedSize"
                    active-class="black--text"
                  >
                    <v-chip
                      filter
                      v-for="size in sizes"
                      :key="size.id"
                      :value="size.id"
                    >
                      {{ size.contentSize }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>

              <div v-if="selectedSize">
                <div
                  v-for="picker in currentItem.optionPickers"
                  :key="picker.id"
                >
                  <v-card
                    class="ma-1"
                    v-if="picker.optionPickerType == 'InfoOnly'"
                  >
                    <!--  <v-card-title>
                                <span style="font: Cairo;"  class="title font-weight-light">{{picker.name}}</span>
                            </v-card-title> -->
                    <v-card-text>
                      <p>
                        {{ picker.name }}
                      </p>
                      <p>
                        {{ picker.description }}
                      </p>
                    </v-card-text>
                  </v-card>

                  <v-card
                    class="ma-1"
                    v-if="picker.optionPickerType == 'TextInput'"
                  >
                    <!-- <v-card-title>
                                <span style="font: Cairo;" class="title font-weight-light">{{picker.name}}</span>
                            </v-card-title> -->
                    <v-card-text>
                      <p>
                        {{ picker.name }}
                      </p>
                      <v-textarea
                        :rules="[(v) => (v || '').length <= 25 || '25']"
                        counter="25"
                        outlined
                        name="w"
                        :label="picker.description"
                        v-model="allSelectedOptions[`${picker.id}`].text"
                      ></v-textarea>
                    </v-card-text>
                  </v-card>

                  <v-card
                    class="ma-1"
                    v-if="picker.optionPickerType == 'SingleSelect'"
                  >
                    <v-card-text>
                      <p>
                        {{ picker.name }}
                      </p>
                      <v-select
                        :label="picker.description"
                        :items="picker.options"
                        item-text="name"
                        item-value="id"
                        v-model="
                          allSelectedOptions[`${picker.id}`].selectedOption
                        "
                      >
                      </v-select>
                    </v-card-text>
                  </v-card>

                  <v-card
                    class="ma-1"
                    v-if="picker.optionPickerType == 'ImageOnly'"
                  >
                    <v-card-text>
                      <p>
                        {{ picker.name }}
                      </p>
                      <v-select
                        :label="picker.description"
                        :items="picker.options"
                        item-text="name"
                        item-value="id"
                        v-model="
                          allSelectedOptions[`${picker.id}`].selectedOption
                        "
                      >
                        <template v-slot:item="{ item }">
                          <v-card class="mx-auto my-1" max-height="120">
                            <v-img
                              height="110"
                              :src="item.imageUrl"
                              contain
                            ></v-img>
                          </v-card>
                        </template>
                      </v-select>
                    </v-card-text>
                  </v-card>

                  <v-card
                    class="ma-1"
                    v-if="picker.optionPickerType == 'SingleSelectWithImage'"
                  >
                    <v-card-text>
                      <p>
                        {{ picker.name }}
                      </p>
                      <v-select
                        :label="picker.description"
                        :items="picker.options"
                        item-text="name"
                        item-value="id"
                        v-model="
                          allSelectedOptions[`${picker.id}`].selectedOption
                        "
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-avatar tile>
                            <v-img
                              contain
                              class="mx-1"
                              max-width="25"
                              :src="item.imageUrl"
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-card-text>
                  </v-card>

                  <v-card
                    :loading="
                      allSelectedOptions[`${picker.id}`].isUploadingImage
                    "
                    class="ma-1"
                    v-if="picker.optionPickerType == 'FileAttachment'"
                  >
                    <v-card-text>
                      <p>{{ picker.name }}</p>
                      <v-card
                        elevation="0"
                        class="mx-auto"
                        max-width="150"
                        @click="launchFilePicker(`${picker.id}`)"
                      >
                        <v-avatar
                          size="150px"
                          v-ripple
                          v-if="!allSelectedOptions[`${picker.id}`].logoFile"
                          class="grey lighten-3 mb-3"
                        >
                          <v-icon> mdi-plus-circle</v-icon>
                        </v-avatar>
                        <v-avatar size="150px" v-ripple v-else class="mb-3">
                          <img
                            :src="
                              allSelectedOptions[`${picker.id}`]
                                .selectedImageUrl
                            "
                            alt="avatar"
                          />
                        </v-avatar>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </div>

                <v-card class="mx-1 my-1">
                  <v-card-text>
                    <v-dialog
                      ref="dateDialog"
                      v-model="showDatePicker"
                      :return-value.sync="selectedDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selectedDate"
                          :label="localizedText[language].chooseDay"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="black lighten-1"
                        header-color="black"
                        :locale="locale"
                        :min="minDate"
                        :show-current="false"
                        v-model="selectedDate"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="showDatePicker = false"
                        >
                          {{ localizedText[language].cancel }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dateDialog.save(selectedDate)"
                        >
                          {{ localizedText[language].ok }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>

                    <div>
                      {{ localizedText[language].serviceDurationMessage }}
                    </div>
                  </v-card-text>
                </v-card>

                <v-alert v-if="showError" type="error" class="ma-2">
                  {{ errorMessage }}
                </v-alert>
                <v-card elevation="0" class="ma-2 d-flex flex-nowrap">
                  <v-icon class="ma-2" color="black" @click="increaseQuantity"
                    >mdi-plus-circle-outline</v-icon
                  >
                  <v-chip class="ma-2">{{ quantity }}</v-chip>
                  <v-icon
                    class="ma-2"
                    color="black"
                    :disabled="quantity <= 20"
                    @click="decreaseQuantity"
                  >
                    mdi-minus-circle-outline</v-icon
                  >

                  <v-btn
                    :loading="isAddingToCart"
                    dark
                    class="ma-2 flex-grow-1 flex-shrink-1"
                    @click="addToCart"
                  >
                    {{ localizedText[language].addToCart }}
                  </v-btn>
                </v-card>
              </div>

              <div v-else>
                <v-alert class="ma-2" outlined>
                  <div>
                    {{ localizedText[language].pleaseChooseSize }}
                  </div>
                </v-alert>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-snackbar v-model="snackbar">
      <v-progress-linear indeterminate></v-progress-linear>
    </v-snackbar>

    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @change="logoFileSelected($event.target.files)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "GiveawayItemComponent",
  //********************** */
  props: {
    itemId: {
      type: Number,
    },
  },
  //********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      currency: "currency",
      token: "token",
    }),
    ...mapGetters("Catalog", {
      catalog: "catalog",
    }),
    ...mapGetters("Cart", {
      uploadedFileInfo: "uploadedFileInfo",
      cart: "cart",
    }),
    locale: function () {
      return this.language == "en" ? "en-us" : "ar-kw";
    },
    minDate: function () {
      let today = new Date();

      let result = new Date();

      result.setDate(today.getDate() + 3);

      return result.toISOString().substr(0, 10);
    },
  },
  data: () => ({
    breadcrumbItems: [],
    currentImageIndex: 0,
    snackbar: false,
    showError: false,
    errorMessage: "",
    hasMamool: false,
    quantity: 20,
    dateDialog: false,
    showDatePicker: false,
    selectedDate: null,
    selectedSize: null,
    isUploadingImage: false,
    currentUploadingId: "",
    allSelectedOptions: {},
    item: {},
    currentItem: {},

    sizes: [],

    itemsDictionary: {},

    localizedText: {
      en: {
        chooseDay: "Choose day",
        startTime: "Start time",
        endTime: "End time",
        ok: "Ok",
        cancel: "Cancel",
        pleaseChooseSize: "Please choose perfume bottel size.",
        chooseSize: "Choose size",
        logo: "You can upload a logo to print on the box.",
        serviceDurationMessage: "It will take 3 days to prepare your order.",
        addToCart: "Add to cart",
        choosePerfumeError:
          "You have to pick a perfume before adding the item to cart.",
        chooseMamoolError:
          "You have to pick a Mamool before adding the item to cart.",
        chooseBoxColorError:
          "You have to pick a box color before adding the item to cart.",
        chooseFontError:
          "You have to pick a font before adding the item to cart.",
        chooseDateError:
          "You have to pick a date before adding the item to cart.",
        pleaseChoose: "Please choose ",
      },
      ar: {
        chooseDay: "اختر اليوم",
        startTime: "وقت البدء",
        endTime: "وقت النهاية",
        ok: "تم",
        cancel: "الغاء",
        pleaseChooseSize: "الرجاع اختيار قياس قرشة العطر.",
        chooseSize: "اختر قياس القرشة",
        logo: "يمكنك رفع صور شعار لطباعتها على العلبة",
        serviceDurationMessage: "يستغرق اعداد الطلب 3 أيام",
        addToCart: "أضف إلى السلة",
        choosePerfumeError: "يجب اختيار العطر قبل الاضافة إلى السلة.",
        chooseMamoolError: "يجب اختيار المعمول قبل الاضافة إلى السلة.",
        chooseBoxColorError: "يجب اختيار لون العلبة قبل الاضافة إلى السلة.",
        chooseDateError: "يرجى اختيار تاريخ التوصيل",
        pleaseChoose: "االرجاء اختيار ",
      },
    },
    isAddingToCart: false,
  }),

  filters: {
    localizedText: function (text, language) {
      if (language == "en") {
        return text.english;
      } else {
        return text.arabic;
      }
    },
  },

  methods: {
    loadItem: function () {
      this.item = this.catalog.items.find((i) => i.id == this.itemId);

      let categoryId = this.item.categoryId;

      let category = this.catalog.categories.find((c) => c.id == categoryId);

      console.log(category);

      this.breadcrumbItems.push({
        text: this.localizedText[this.language].home,
        disabled: false,
        to: "/",
        color: "black",
        imageUrl: require("@/assets/square.png"),
      });

      this.breadcrumbItems.push({
        text: category.name,
        disabled: false,
        to: `/Category/${category.outlineId}`,
        color: "black",
        imageUrl: category.imageUrl,
      });

      this.breadcrumbItems.push({
        text: this.item.name,
        disabled: true,
        to: `/item`,
        color: "black",
        imageUrl: "",
      });
    },
    setCurrentImageIndex: function (i) {
      this.currentImageIndex = i;
    },
    displayError: function (message) {
      this.errorMessage = message;
      let self = this;
      self.showError = true;
      setTimeout(function () {
        self.showError = false;
      }, 50000);
    },
    isValid: function () {
      let ln = this.language;

      for (var key in this.allSelectedOptions) {
        const element = this.allSelectedOptions[key];

        if (element.optionPickerType == "MultipleRepeatedSelect") {
          if (element.selectedOptions.length <= 0) {
            return `${this.localizedText[ln].pleaseChoose} ${element.name}`;
          }
        } else if (
          element.optionPickerType != "TextInput" &&
          element.optionPickerType != "InfoOnly" &&
          element.optionPickerType != "FileAttachment"
        ) {
          if (element.selectedOption <= 0) {
            return `${this.localizedText[ln].pleaseChoose} ${element.name}`;
          }
        }
      }

      if (!this.selectedDate) {
        return this.localizedText[ln].chooseDateError;
      }

      return "yes";
    },

    addToCart: async function () {
      let e = this.isValid();

      if (e != "yes") {
        this.displayError(e);
        return;
      }

      var options = [];
      let self = this;
        Object.keys(this.allSelectedOptions).map(function (key, index) {
            let o = self.allSelectedOptions[key];
        options.push({
          pickerId: o.optionPickerId,
          singleSelectedOptionId: o.selectedOption,
          text: o.text,
          logoImageName: o.logoImageName,
        })
      });

     /* let options = this.allSelectedOptions.map((o) => {
        return {
          pickerId: o.optionPickerId,
          singleSelectedOptionId: o.selectedOption,
          text: o.text,
          logoImageName: o.logoImageName,
        };
      });*/

      let input = {
        itemId: this.currentItem.id,
        options: options,
        cartId: this.cart.id,
        deliveryDate: this.deliveryDate,
        deliveryDay: this.deliveryDate,
        quantity: this.quantity,
        language: this.language,
      };
      this.isAddingToCart = true;
      await this.$store.dispatch("Cart/addGiveawayItem", input);
      this.isAddingToCart = false;
      //console.log(input);

      /*let model = { 
          itemId :  this.currentItem.id, 
          deliveryDate: this.selectedDate,
          options, deliveryDay, quantity, language };

      await this.$store.dispatch("Cart/addGiveawayItem", model);*/

      //console.log(input);

      //addToCartHandler(input);
    },
    increaseQuantity: function () {
      this.quantity += 1;
    },
    decreaseQuantity: function () {
      this.quantity -= 1;
    },
    sizeChanged: function () {
      if (this.selectedSize) {
        this.currentItem = this.itemsDictionary[this.selectedSize];
      } else {
        this.currentItem = {
          id: this.item.id,
          name: this.item.name,
          shortDescription: this.item.shortDescription,
          description: this.item.description,
          unitPrice: 0,
          contentSize: this.item.contentSize,
          optionPickers: this.item.optionPickers,
          mainThumbnailUrl: this.item.mainThumbnailUrl,
        };
      }

      this.preparePickers();
    },

    launchFilePicker: function (uploadId) {
      this.currentUploadingId = uploadId;
      this.$refs.uploader.click();
    },

    logoFileSelected: async function (file) {
      console.log(file);
      let imageFile = file[0];

      console.log(imageFile);

      this.allSelectedOptions[this.currentUploadingId].logoFile = imageFile;

      let imageURL = URL.createObjectURL(imageFile);

      this.allSelectedOptions[this.currentUploadingId].selectedImageUrl =
        imageURL;

      this.allSelectedOptions[this.currentUploadingId].isUploadingImage = true;

      this.snackbar = true;

      await this.$store.dispatch("Cart/upload", imageFile);

      this.allSelectedOptions[this.currentUploadingId].logoImageName =
        this.uploadedFileInfo.fileName;
      this.allSelectedOptions[this.currentUploadingId].isUploadingImage = false;
      this.snackbar = false;
    },

    carouselItemClicked: function (carouselItem, event) {
      // imageTappedHandler(carouselItem);
      console.log(event);
      // event.stopPropagation();
    },
    preparePickers: function () {
      this.allSelectedOptions = {};
      if (this.currentItem.optionPickers.length > 0) {
        for (
          let index = 0;
          index < this.currentItem.optionPickers.length;
          index++
        ) {
          const picker = this.currentItem.optionPickers[index];
          this.allSelectedOptions[`${picker.id}`] = {
            selectedOptions: [],
            selectedOption: -1,
            text: "",
            optionPickerType: picker.optionPickerType,
            optionPickerId: picker.id,
            name: picker.name,
            isUploadingImage: false,
            logoImageName: "",
            selectedImageUrl: "",
            logoFile: null,
          };
        }
      }
    },
    prepareVariations: function () {
      this.itemsDictionary[this.item.id] = {
        id: this.item.id,
        name: this.item.name,
        shortDescription: this.item.shortDescription,
        description: this.item.description,
        unitPrice: this.item.unitPrice,
        contentSize: this.item.contentSize,
        optionPickers: this.item.optionPickers.sort(
          (a, b) => a.sortOrder - b.sortOrder
        ),
        mainThumbnailUrl: this.item.mainThumbnailUrl,
      };

      if (this.item.variations) {
        var variations = this.item.variations.map(function (item) {
          return {
            id: item.id,
            name: item.name,
            shortDescription: item.shortDescription,
            description: item.description,
            unitPrice: item.unitPrice,
            contentSize: item.contentSize,
            optionPickers: item.optionPickers.sort(
              (a, b) => a.sortOrder - b.sortOrder
            ),
            mainThumbnailUrl: item.mainThumbnailUrl,
          };
        });

        for (let index = 0; index < variations.length; index++) {
          const element = variations[index];
          this.itemsDictionary[element.id] = element;
        }
      }

      this.currentItem = {
        id: this.item.id,
        name: this.item.name,
        shortDescription: this.item.shortDescription,
        description: this.item.description,
        unitPrice: 0,
        contentSize: this.item.contentSize,
        optionPickers: this.item.optionPickers,
        mainThumbnailUrl: this.item.mainThumbnailUrl,
      };

      this.sizes = Object.values(this.itemsDictionary).map(function (item) {
        return {
          id: item.id,
          contentSize: item.contentSize,
        };
      });
    },
  },

  created: function () {},

  mounted: function () {
    this.loadItem();
    this.prepareVariations();
    this.preparePickers();
  },
};
</script>

<style>
</style>